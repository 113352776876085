<template>
  <section style="transition: all .5s ease;">
    <div class="modal d-flex justify-content-end">
      <div class="bg-white p-2 w-50 overflow-y-scroll">
        <div class="d-flex align-items-center pb-1 border-b mb-1">
          <strong class="my-0 text-dark text-10">Lengkapi Informasi Assignment</strong>
          <span class="ml-auto k-close-circle cursor-pointer text-10" @click="$emit('close')"></span>
        </div>
        <b-overlay
          variant="light"
          :show="loading"
          spinner-variant="primary"
          blur="0"
          opacity=".5"
          rounded="sm"
        >
          <validation-observer ref="formRules"
            v-slot="{ invalid }">
            <b-row>
              <b-col
                md="12"
              >
                <b-form-group
                  label-class="font-medium text-7 text-black"
                >
                  <template #label>
                    Nama Talent
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    name="Nama Talent"
                    rules="required"
                  >
                    <v-select
                      v-model="talent"
                      :reduce="option => option"
                      :options="listTalent"
                      placeholder="Ketik untuk mencari..."
                      @search="(search) => search.length > 0 ? onSearchTalent(search) : ''"
                    />
                    <small class="text-danger d-block">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                md="12"
              >
                <b-form-group
                  label-class="font-medium text-7 text-black"
                >
                  <template #label>
                    Nama Partner
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    name="Nama Partner"
                    rules="required"
                  >
                    <v-select
                      v-model="partner"
                      :reduce="option => option"
                      :options="listPartner"
                      placeholder="Ketik untuk mencari..."
                      @search="(search) => search.length > 0 ? onSearchPartner(search) : ''"
                      @input="handleInput"
                    />
                    <small class="text-danger d-block">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                md="12"
              >
                <b-form-group
                  label-class="font-medium text-7 text-black"
                >
                  <template #label>
                    Leader
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    name="Leader"
                    rules="required"
                  >
                    <v-select
                      v-model="leader"
                      label="name"
                      :reduce="option => option"
                      :options="listLeader"
                      placeholder="Ketik untuk mencari..."
                      @search="(search) => search.length > 0 ? onSearchLeader(search) : ''"
                    />
                    <small class="text-danger d-block">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                md="12"
              >
                <b-form-group
                  label-class="font-medium text-7 text-black"
                >
                  <template #label>
                    Device
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    name="Device"
                    rules="required"
                  >
                    <v-select
                      v-model="device"
                      label="device_name"
                      :reduce="option => option"
                      :options="listDevice"
                      placeholder="Ketik untuk mencari..."
                      :disabled="!partner ? true : false"
                    />
                    <small class="text-danger d-block">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                md="12"
              >
                <b-form-group
                  label-class="font-medium text-7 text-black"
                >
                  <template #label>
                    Kantor
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    name="Kantor"
                    rules="required"
                  >
                    <v-select
                      v-model="office"
                      label="working_space_name"
                      :reduce="option => option"
                      :options="listOffice"
                      placeholder="Ketik untuk mencari..."
                    />
                    <small class="text-danger d-block">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                md="12"
              >
                <b-form-checkbox
                  v-model="isNewPartner"
                  :value="true"
                  :unchecked-value="false"
                >
                  Talent di rekrut dari partner baru
                </b-form-checkbox>
              </b-col>
            </b-row>
            <div class="d-flex justify-content-end mt-auto">
              <button
                class="button-primary w-[200px] py-[8px]"
                :disabled="invalid"
                @click.prevent="submit()"
              >
                Tambahkan
              </button>
            </div>
          </validation-observer>
        </b-overlay>
      </div>
    </div>
    <div class="modal-backdrop"></div>
  </section>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { komtimAxiosIns } from '@/libs/axios'
import { alertError, alertSuccess } from '@toast'
import vSelect from 'vue-select'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    vSelect,
  },
  props: {
    talentExist: Array,
    deviceExist: Array,
  },
  data() {
    return {
      loading: false,
      loadingSubmit: false,
      alertError,
      alertSuccess,

      listTalent: [],
      listPartner: [],
      listLeader: [],
      listDevice: [],
      listOffice: [],

      talent: null,
      partner: null,
      leader: null,
      device: null,
      office: null,
      isNewPartner: false,
    }
  },
  beforeMount() {
    this.getListOffice()
  },
  methods: {
    async submit() {
      this.loadingSubmit = true

      const data = {
        talent_id: this.talent.id,
        talent_name: this.talent.full_name,
        partner_id: this.partner.id,
        partner_name: this.partner.name,
        device_id: this.device.id,
        device_name: this.device.device_name,
        staff_id: this.leader.id,
        staff_name: this.leader.name,
        working_space_id: this.office.id,
        working_space_name: this.office.working_space_name,
        is_new_partner: this.isNewPartner,
      }

      this.$emit('addTalent', data)
      this.$emit('close')
    },
    async getListTalent(keyword) {
      this.offset = 0
      const params = `offset=0&limit=20&status=${'selected,hired,non job'}&keyword=${keyword}`
      const url = `v1/talents?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          const { data } = res.data
          const filtered = data.filter(el => !this.talentExist.includes(el.id))
          this.listTalent = filtered.map(e => ({ ...e, label: `${e.id} - ${e.full_name}` }))
        })
        .catch(err => {
          this.alertError(err)
          this.loading = false
        })
    },
    searchTalent: _.debounce((search, it) => {
      it.getListTalent(search)
    }, 500),
    onSearchTalent(search) {
      this.searchTalent(search, this)
    },
    async getListPartner(keyword) {
      this.offset = 0
      const params = `offset=0&limit=20&status=registered,active,pause,off&keyword=${keyword}`
      const url = `v1/partners?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          const { data } = res.data
          this.listPartner = data.map(e => ({ ...e, label: `${e.no_partner} - ${e.name}` }))
        })
        .catch(err => {
          this.alertError(err)
          this.loading = false
        })
    },
    searchPartner: _.debounce((search, it) => {
      it.getListPartner(search)
    }, 500),
    onSearchPartner(search) {
      this.searchPartner(search, this)
    },
    async getListLeader(keyword) {
      this.offset = 0
      const params = `offset=0&limit=20&position_name=Team Lead Non Advertiser,Team Lead Advertiser&keyword=${keyword}`
      const url = `v1/staffs?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          const { data } = res.data
          this.listLeader = data
        })
        .catch(err => {
          this.alertError(err)
          this.loading = false
        })
    },
    searchLeader: _.debounce((search, it) => {
      it.getListLeader(search)
    }, 500),
    onSearchLeader(search) {
      this.searchLeader(search, this)
    },
    async getListOffice() {
      this.loading = true
      this.offset = 0
      const params = 'status=true'
      const url = `v1/working_spaces/resource?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          this.loading = false
          const { data } = res.data
          this.listOffice = data
        })
        .catch(err => {
          this.loading = false
          this.alertError(err)
        })
    },
    async getListDevice() {
      this.loading = true
      this.offset = 0
      const params = `offset=0&limit=20&holder_name=IT Support&partner_id=${this.partner.no_partner}`
      const url = `v1/devices?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          this.loading = false
          const { data } = res.data
          this.listDevice = data.filter(el => !this.deviceExist.includes(el.id))
        })
        .catch(err => {
          this.loading = false
          this.alertError(err)
        })
    },
    handleInput(val) {
      this.device = null
      if (val) {
        this.getListDevice()
      }
    },
  },
}
</script>
